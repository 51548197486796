import React, {ReactNode} from "react";
import request from "superagent"
import { ScreenCloud } from "../../ScreenCloudReactApp";
import Loader from "../Loader";
import Slide from "../Slide";
import * as Sentry from "@sentry/browser";

interface Token {
  authToken: string;
  expiresAt: number;
}

interface State {
  rendering?: string;
  error: any;
}

interface Props {
  siteId?: string;
  refreshIntervalSeconds?: number;
  sc: ScreenCloud;
}

const MAX_AUTH_TOKEN_ATTEMPTS = 3;
const AUTH_TOKEN_DELAY_MODIFIER = 2000;

export default class Dashboards extends React.Component<Props, State> {
  private authTokenAttempts = 0;
  private authTokenTimeout?: number;
  private readonly siteId: string;
  private intervalTimer: NodeJS.Timeout | undefined;
  private heartbeatIntervalTimer: NodeJS.Timeout | undefined;
  private sc: ScreenCloud;
  private rendererLocation: string = "";
  private rendererId: string = "";
  private token: Token = { authToken: '', expiresAt: 0 };

  constructor(props: Props) {
    super(props);
    this.sc = props.sc;
    this.siteId = props.siteId!;
    this.state = {
      rendering: "",
      error: undefined
    };
  }

  /**
   * Avoiding async life cycle method by calling the asynchronous function and handling the Promise returned.
   */
  componentDidMount(): void {
    try {
      this.beginCloudRendering()
          .then(() => {})
          .catch(rejection => {
            this.setSentryExtras();

            console.error(`Promise rejection during mounting: ${rejection.toString()}`);
            this.setState({error: rejection});
          });
    }
    catch (error) {
      this.setSentryExtras();

      console.error(`Error during mounting: ${error}`);
      this.setState({error});
    }
  }

  setSentryExtras = () => {
    Sentry.setExtras({
      "siteId": this.siteId,
      "rendererLocation": this.rendererLocation,
      "rendererId": this.rendererId,
    });
  };

  componentWillUnmount(): void {
    this.clearIntervals();

    if (this.authTokenTimeout) {
      window.clearTimeout(this.authTokenTimeout);
    }
  }

  /**
   * Effectively the "main" function of the Dashboard GUI that establishes everything required for rendering.
   *
   * The logic is as follows:
   * 1. Create Renderer - returns renderer's location
   * 2. Await renderer's status moving to value of RENDERING
   * 3. Load the rendering
   * 4. Establish interval to retrieve latest rendering every `refreshIntervalSeconds` seconds
   */
  beginCloudRendering = async () => {
    console.debug(">>> beginCloudRendering");
    if (!this.props.siteId) {
      this.setSentryExtras();
      console.error(`No Site ID received.`);
      return Promise.reject(`No Site ID received.`);
    }

    try {
      this.token = await this.getAuthToken();
    }
    catch (error) {
      this.setSentryExtras();
      return Promise.reject(`unable to retrieve token ${JSON.stringify(error)}`);
    }

    try {
      this.rendererLocation = await this.createRenderer();
    }
    catch (error) {
      this.setSentryExtras();
      return Promise.reject(`unable to create renderer ${JSON.stringify(error)}`);
    }

    try {
      this.rendererId = Dashboards.parseRendererId(this.rendererLocation);
    }
    catch (error) {
      this.setSentryExtras();
      return Promise.reject(`Unable to parse renderer ID: ${error}`);
    }

    this.heartbeatIntervalTimer = setInterval(this.emitHeartbeat, 60 * 1000); // 60 seconds

    try {
      await this.waitForRendererToBeReady(this.rendererLocation);
    }
    catch (error) {
      this.setSentryExtras();
      return Promise.reject(`Problem awaiting renderer ready state: ${error}`);
    }

    try {
      await this.waitForFirstRendering();
    }
    catch (error) {
      this.setSentryExtras();
      return Promise.reject(`Problem awaiting first rendering: ${error}`);
    }

    try {
      await this.loadRendering();
    }
    catch (error) {
      this.setSentryExtras();
      return Promise.reject(`Problem setting rendering: ${error}`);
    }

    this.intervalTimer = setInterval(this.refreshRendering, this.props.refreshIntervalSeconds! * 1000);
    console.debug("<<< beginCloudRendering");
  };

  clearIntervals = () => {
    console.debug(">>> clearIntervals");
    if (this.intervalTimer) clearInterval(this.intervalTimer);
    if (this.heartbeatIntervalTimer) clearInterval(this.heartbeatIntervalTimer);
    console.debug("<<< clearIntervals");
  };

  emitHeartbeat = async () => {
    console.debug(">>> emitHeartbeat");
    try {
      await request
          .post(process.env.REACT_APP_API_BASE_URL + `/v1/management/sites/${this.siteId}/renderers/${this.rendererId}/heartbeats/viewer`)
          .set({Authorization: `Bearer ${this.token.authToken}`})
          .retry(2)
          .timeout(20000)
          .send()
    }
    catch (error) {
      this.setSentryExtras();
      const errMsg = `Error emitting a heartbeat: got ${error}`;
      console.error(errMsg);
      throw new Error(errMsg);
    }
    console.debug("<<< emitHeartbeat");
  };

  createRenderer = async () => {
    try {
      const createRendererResponse = await request
          .post(process.env.REACT_APP_API_BASE_URL + `/v1/management/sites/${this.siteId}/renderers`)
          .set({Authorization: `Bearer ${this.token.authToken}`})
          .retry(2)
          .timeout(60000)
          .send(
              {
                viewport: {
                  width: window.innerWidth,
                  height: window.innerHeight,
                  scale: 1
                },
                parameters: {
                  ...this.props.sc.context.screenData
                }
              });

      if (createRendererResponse && (createRendererResponse.status === 200 || createRendererResponse.status === 201)) {
        return createRendererResponse.header['location'];
      }
    }
    catch (error) {
      this.setSentryExtras();
      const errMsg = `Unexpected createRendererResponse status: got ${error}, wanted 201`;
      console.error(errMsg);
      throw new Error(errMsg);
    }
  };

  /**
   * Returns the ID of the Renderer located at the supplied location.
   *
   * The URL is expected to be of the form http://host:port/sites/123456/renderers/9876543, where the final path
   * position is the ID of the Renderer. In this case 9876543 will be returned.
   *
   * @param rendererLocation - the URL of the Renderer whose ID is desired
   */
  private static parseRendererId(rendererLocation: string): string {
    const bits = rendererLocation.split("/");
    return bits[bits.length - 1];
  }

  sleep = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  waitForRendererToBeReady = async (rendererLocation: string) => {
    let rendererStatus = "";
    let counter = 0;

    do {
      console.debug(counter);
      await this.sleep(2750); // give renderer time to start
      rendererStatus = await this.getRendererStatus(rendererLocation);
    } while (rendererStatus !== "RENDERING" && ++counter < 21); // equates to about a minute waiting, given sleep above

    console.debug(rendererStatus, counter);

    if (counter === 3 && rendererStatus !== "RENDERING") {
      throw new Error(`Renderer in incorrect status: wanted RENDERING, got ${rendererStatus}`);
    }
  };

  getRendererStatus = async (rendererLocation: string) => {
    const rendererStatusResponse = await request
        .get(process.env.REACT_APP_API_BASE_URL + rendererLocation + "/status")
        .set({Authorization: `Bearer ${this.token.authToken}`})
        .retry(2)
        .timeout(60000);

    if (rendererStatusResponse.ok) {
      return rendererStatusResponse.body.status;
    }
    else {
      this.setSentryExtras();
      const errMsg = `Unexpected rendererStatusResponse status: got ${rendererStatusResponse.status}, wanted 2xx`;
      console.log(errMsg);
      console.log(JSON.stringify(rendererStatusResponse, null, 2));
      throw new Error(errMsg);
    }
  };

  waitForFirstRendering = async () => {
    console.debug(`>>> waitForFirstRendering`);
    const MAX_ATTEMPTS = -1; // iterate indefinitely for v0.8
    const NOT_CREATED_YET_STATUS = 204;
    let renderingStatus = -1;
    let counter = 0;

    // effectively an infinite loop for v0.8 due to MAX_ATTEMPTS above
    do {
      console.debug(`waiting for rendering ${counter}`);
      await this.sleep(1000); // give renderer time to start
      renderingStatus = await this.getRenderingStatus();
    } while (renderingStatus === NOT_CREATED_YET_STATUS && ++counter != MAX_ATTEMPTS);

    console.debug(renderingStatus, counter);

    if (counter >= MAX_ATTEMPTS && renderingStatus !== 200) {
      this.setSentryExtras();
      throw new Error(`Unable to get rendering: wanted 200, got ${renderingStatus}`);
    }
    console.debug(`<<< waitForFirstRendering`);
  };

  getRenderingStatus = async () => {
    console.debug(`>>> getRenderingStatus`);
    try {
      const renderingStatus = await request
          .get(`${process.env.REACT_APP_API_BASE_URL}/v1/media/sites/${this.siteId}/renderers/${this.rendererId}/stream?token=${this.token.authToken}&v=${Date.now()}`)
          .timeout(60000);

      console.debug(`<<< getRenderingStatus`);
      return renderingStatus ? renderingStatus.status : 500;
    }
    catch (error) {
      this.setSentryExtras();
      console.error(`Unexpected getRenderingStatus response: ${error}`);
      return 500;
    }
  };

  loadRendering = async () => {
    console.debug(">>> loadRendering");
    const renderingUrl = `${process.env.REACT_APP_API_BASE_URL}/v1/media/sites/${this.siteId}/renderers/${this.rendererId}/stream?token=${this.token.authToken}&v=${Date.now()}`;
    const image = new Image();
    image.src = renderingUrl;
    image.onload = () => {
      this.setState({rendering: renderingUrl});
    };
    console.debug("<<< loadRendering");
  };

  refreshRendering = async () => {
    try {
      const rendererStatus = await this.getRendererStatus(this.rendererLocation);

      if (rendererStatus !== "RENDERING") {
        this.setState({
          error: new Error(`Renderer ${this.rendererId} for Site ${this.siteId} is not in RENDERING status: got ${rendererStatus}`)
        });
      }
      else {
        await this.loadRendering();
      }
    }
    catch (error) {
      this.setSentryExtras();
      this.setState({error});
    }
  };

  render(): ReactNode {
    console.debug(">>> render");

    if (this.state.error) {
      this.clearIntervals();
      throw this.state.error; // This will be caught by the ErrorBoundary component
    }

    return (
        <>
          {this.state.rendering && (
              <Slide url={this.state.rendering} onError={() => this.sc.emitFinished()} />
          )}
          {!this.state.rendering && !this.state.error && (
              <Loader/>
          )}
        </>
    );
  }

  getAuthToken = async () => {
    do {
      try {
        ++this.authTokenAttempts;
        return await this.props.sc!.requestAuthToken();
      } catch (err) {
        if (this.authTokenAttempts === MAX_AUTH_TOKEN_ATTEMPTS) {
          this.setState(() => { throw err });
          return;
        }

        await this.wait(this.authTokenAttempts * AUTH_TOKEN_DELAY_MODIFIER);
      }
    } while (this.authTokenAttempts < MAX_AUTH_TOKEN_ATTEMPTS);
  }

  wait = (ms: number) => {
    return new Promise((resolve, reject) => this.authTokenTimeout = window.setTimeout(resolve, ms));
  }
};
