import React, { ReactNode, Component, ErrorInfo } from "react";
import { ScreenCloud } from "../../ScreenCloudReactApp";
import largeIcon from "../../assets/icon_lg.svg";
import "./ErrorBoundary.scss";
import * as Sentry from "@sentry/browser";

interface Props {
    sc: ScreenCloud;
}

interface State {
    error?: Error;
    hasError: boolean;
    eventId?: string;
    errorInfo?: ErrorInfo;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            error: undefined,
            eventId: undefined,
            errorInfo: { componentStack: "" }
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.debug(">>> componentDidCatch");
        console.error(`caught: ${JSON.stringify(error)}`);
        this.setState({
            hasError: true,
            error: error,
            errorInfo: errorInfo
        });

        Sentry.withScope(scope => {
            scope.setExtra("errorInfo", errorInfo);
            const eventId = Sentry.captureException(error);
            this.setState({ eventId });
        });

        console.debug("<<< componentDidCatch");
    }

    render(): ReactNode {
        console.debug(">>> ErrorBoundary.render");
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const isTickerTape = windowWidth > windowHeight * 7;

        if (this.state.hasError) {
            let { eventId } = this.state;

            if (eventId) {
                console.log(`Event ID ${eventId}`);
                eventId = eventId.substring(0, 8);
            }

            return (
                <div
                    className={`error-container error-container-${
                        isTickerTape ? "tickertape" : "zones"
                    } `}
                >
                    <div className="left-panel">
                        <img src={largeIcon} alt="Error icon" />
                    </div>
                    <div className="right-panel">
                        <div>
                            <h1>An error has occurred :(</h1>
                            {!isTickerTape && (
                                <h2>Please contact ScreenCloud support if the error persists</h2>
                            )}
                            <p>Event ID: {eventId}</p>
                            <p>Error Msg: {this.state.error!.message}</p>
                            <p>More info: {JSON.stringify(this.state.error)}</p>
                        </div>
                    </div>
                </div>
            );
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
