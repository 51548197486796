import React from "react";
import { ScreenCloud } from "../../ScreenCloudReactApp";
import Dashboards from "../../components/Dashboards";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";

interface Props {
  siteId?: string;
  refreshIntervalSeconds?: number;
  sc: ScreenCloud;
}

export const AppContainer = (props: Props): JSX.Element => {
  return <ErrorBoundary {...props}><Dashboards {...props} /></ErrorBoundary>;
}
