import React, { useState, useEffect } from "react";

interface Props {
  fallback: JSX.Element;
  errorComponent: JSX.Element;
  url: string;
  onError: Function;
  className?: string;
  style?: React.CSSProperties;
}

const BackgroundImageLoader = ({
  fallback,
  url,
  onError,
  errorComponent,
  style = {},
  ...props
}: Props): JSX.Element | null => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.onload = (e) => {
      console.log("onload", e);
      setLoaded(true);
    };
    image.onerror = (e) => {
      console.log("onerror", e);
      setError(true);
    };

    image.src = url;
  }, []);

  if (error) {
    onError();
    return errorComponent;
  }

  if (!loaded) {
    return fallback;
  }

  return (
    <div style={{ ...style, backgroundImage: `url('${url}')` }} {...props} />
  );
};

export default BackgroundImageLoader;
