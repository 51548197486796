import React from "react";
import ReactDOM from "react-dom";
import "whatwg-fetch"; // required until cypress supports fetch API. https://github.com/cypress-io/cypress/issues/95
import "normalize.css"; // Import before any custom CSS. Smoothes out browser differences.

import { AppContainer } from "./containers/AppContainer/AppContainer";
import ScreenCloudReactApp from "./ScreenCloudReactApp";
import { defaultTheme } from "./defaultTheme";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import "./index.css";

Sentry.init({ 
  dsn: process.env.REACT_APP_DSN,
  environment: process.env.REACT_APP_ENVIRONMENT,
  enabled: process.env.REACT_APP_ENVIRONMENT !== "dev" // Disable in development
});

ReactDOM.render(
  <ScreenCloudReactApp defaultTheme={defaultTheme}>
    {sc => <AppContainer sc={sc} {...sc.config} />}
  </ScreenCloudReactApp>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
