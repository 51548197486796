import React from "react";
import { PushSpinner } from "react-spinners-kit";
import "./style.css";

const Loader = (): JSX.Element => (
  <div className="loader">
    <PushSpinner size={30} color="#fdcf00" />
  </div>
);

export default Loader;
