import React from "react";
import BackgroundImageLoader from "../BackgroundImageLoader";
import Loader from "../Loader";
import ErrorMessage from "../Error";
import "./style.css";

interface Props {
  url: string;
  onError: Function;
}

const Slide = ({ url, onError }: Props): JSX.Element => (
  <div className="slide">
    <BackgroundImageLoader
      className="slide"
      url={url}
      fallback={<Loader />}
      errorComponent={<ErrorMessage />}
      onError={onError}
    />
  </div>
);

export default Slide;
